/**
 * @prettier
 */

import { observer } from 'mobx-react'

import { InlineErrors } from './inline_errors'

export const Field = observer(({ field, i, errors, user, ...props }) => {
  const rowClassName = classNames('base-form-row', {
    'is-first': i === 0,
  })

  const inputClassName = classNames('base-input', {
    [field.className]: Boolean(field.className),
    'is-error': _.get(errors, field.name, false),
  })

  return (
    <div className={rowClassName}>
      <label className="base-form-label" htmlFor={field.name}>
        {field.label}
      </label>
      <input
        type={field.type}
        name={field.name}
        autoFocus={i === 0}
        className={inputClassName}
        value={user[field.name]}
        onChange={props.handleUserFieldChange}
      />
      <InlineErrors errors={errors} name={field.name} />
    </div>
  )
})

Field.propTypes = {
  field: PT.object.isRequired,
  i: PT.number.isRequired,
  errors: PT.object,
  user: PT.object.isRequired,
  handleUserFieldChange: PT.func.isRequired,
}
