/**
 * @prettier
 */

export function AlertsList({ alerts, ListItem = Alert, ...props }) {
  const attrs = _.omit(props, ['listIndex'])

  return (
    <div {...attrs}>
      {_.map(alerts, (alert, i) => {
        return <ListItem key={i} {...alert} />
      })}
    </div>
  )
}

export function Alert({ type, className = '', children, ...props }) {
  const attrs = _.omit(props, ['listIndex'])

  return (
    <div {...attrs} className={`base-form-notice is-shown is-${type} ${className}`}>
      <div className="base-form-notice-left-part">
        <span className="icon icon-cross-styled" />
      </div>
      <div className="base-form-notice-right-part">{children}</div>
    </div>
  )
}

Alert.propTypes = {
  type: PT.oneOf(['error', 'success']).isRequired,
}
