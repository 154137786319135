/**
 * @prettier
 */

import { makeAutoObservable } from 'mobx'

export class AuthStore {
  errors = {}

  formIsBlocked = false

  user = {
    email: '',
    password: '',
    password_confirmation: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  updateErrors = errors => {
    if (errors) {
      this.errors = errors
    } else {
      this.errors = {}
    }
  }

  toggleFormBlocker = shouldBlock => {
    this.formIsBlocked = shouldBlock
  }

  handleUserFieldChange = ({ target: { name, value } }) => {
    this.user[name] = value
  }

  getErrorsData = () => {
    const errorsData = Object.values(this.errors).map(error => {
      return { children: error, type: 'error' }
    })

    if (errorsData.length) {
      errorsData[0].className = 'is-top'
    }

    return errorsData
  }
}
