/**
 * @prettier
 */

import { observer } from 'mobx-react'

import { AlertsList } from 'components/shared/alert'

import { Field } from './field'

export const FormData = observer(({ store, mode, config }) => {
  const { user, errors, formIsBlocked, handleUserFieldChange, getErrorsData } = store

  const submitButtonClasses = classNames('base-btn is-success is-xl', {
    [config.btnClass]: config.btnClass,
    'is-disable': formIsBlocked,
  })

  const footerClasses = classNames('base-form-footer', {
    'is-disabled': formIsBlocked,
  })

  return (
    <div>
      <div className="base-form-body">
        <AlertsList alerts={getErrorsData(errors)} />

        <div className="base-form-group">
          <div className="base-form-group-title">{config.title}</div>
          {config.fields.map((field, i) => (
            <Field
              field={field}
              user={user}
              errors={errors}
              handleUserFieldChange={handleUserFieldChange}
              i={i}
              key={i}
            />
          ))}
        </div>
      </div>

      <div className={footerClasses}>
        {mode === 'login' && (
          <a href={Routes.password_resets_path()} className="base-btn is-transparent">
            Forgot password
          </a>
        )}

        <input
          disabled={formIsBlocked}
          type="submit"
          value={config.btnText}
          className={submitButtonClasses}
        />
      </div>
    </div>
  )
})

FormData.propTypes = {
  store: PT.object.isRequired,
  mode: PT.string.isRequired,
  config: PT.object.isRequired,
}
