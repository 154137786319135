/**
 * @prettier
 */

import { getPrevUrl } from 'utils/etc'

export const generateRedirectLink = ({
  currentUrl = window.location.href,
  path,
} = {}) => {
  const prevUrl = getPrevUrl(currentUrl)

  if (prevUrl && !path.includes('?')) {
    return `${path}?prev_url=${prevUrl}`
  } else {
    return path
  }
}
