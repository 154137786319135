/**
 * @prettier
 */

export const InlineErrors = ({ errors, name, ...props }) => {
  errors = _.get(errors, name, [])
  name = _.get(props, 'hideName') ? '' : name
  const limit = props.limit || Infinity

  if (_.some(errors)) {
    return (
      <div>
        <ul className="base-form-inline-message is-error">
          {errors.slice(0, limit).map((error, i) => (
            <li key={i}>{_.upperFirst(`${name} ${error}`.trim())}</li>
          ))}
        </ul>
      </div>
    )
  } else {
    return null
  }
}

InlineErrors.propTypes = {
  errors: PT.object,
  name: PT.string,
}
