/**
 * @prettier
 */
import { inject, observer, Provider } from 'mobx-react'
import { toJS } from 'mobx'

import { removeEmptyStrings, wrapInto } from 'utils/etc'

import { AuthStore } from './stores/auth_store'
import { FormData } from './components/form_data'
import { generateRedirectLink } from './auth_helpers'

/* -----------------------------
  Utils
----------------------------- */
function createPossibleConfigs({ resetToken }) {
  return {
    login: {
      url: Routes.login_path(),
      type: 'POST',
      btnClass: 'ac-login-button',
      btnText: 'Login',
      title: 'Login',
      hero: 'Welcome',
      fields: [
        { name: 'email', label: 'Your email', type: 'email', className: 'ac-user-email' },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          className: 'ac-user-password',
        },
      ],
    },
    reset_password: {
      url: Routes.password_resets_path(),
      type: 'POST',
      btnClass: 'ac-reset-password-button',
      btnText: 'Reset Password',
      title: 'Reset Password',
      hero: 'Reset Password',
      fields: [{ name: 'email', label: 'Your email', type: 'email' }],
    },
    update_password: {
      url: Routes.password_reset_path(resetToken),
      type: 'PUT',
      resetToken,
      btnClass: 'ac-update-password-button',
      btnText: 'Update Password',
      title: 'Set New Password',
      hero: 'Reset Password',
      fields: [
        { name: 'password', label: 'Password', type: 'password' },
        {
          name: 'password_confirmation',
          label: 'Password confirmation',
          type: 'password',
        },
      ],
    },
  }
}

const UNDEFINED_ERROR = ['Something went wrong']

export const AUTH_AVAILABLE_MODES = ['login', 'reset_password', 'update_password']

/* -----------------------------
  Auth Container
----------------------------- */
export const AuthDumb = observer(
  class AuthDumb extends React.PureComponent {
    static propTypes = {
      authStore: PT.object.isRequired,
      reset_token: PT.string,
      mode: PT.oneOf(AUTH_AVAILABLE_MODES).isRequired,
    }

    static defaultProps = {
      reset_token: '0',
    }

    constructor(props) {
      super(props)

      const { mode, reset_token } = props

      this.config = createPossibleConfigs({ resetToken: reset_token })[mode]
      props.authStore.toggleFormBlocker(true)
    }

    componentDidMount() {
      if (_.includes(window.location.search, 'err_msg=expired')) {
        Validation.showErrorFlash(
          'The password reset link has expired, please try resetting your password again',
          10_000,
        )
      }
    }

    handleSubmit = e => {
      const userData = toJS(this.props.authStore.user)

      e.preventDefault()
      this.props.authStore.updateErrors(null)
      this.props.authStore.toggleFormBlocker(true)
      $.ajax({
        url: this.config.url,
        type: this.config.type,
        data: wrapInto('user', removeEmptyStrings(userData)),
        complete: () => this.props.authStore.toggleFormBlocker(false),
        success: this.handleSuccess,
        error: this.handleRequestErrors,
      })
    }

    handleChange = () => {
      const shouldBlock = !_.values(this.props.authStore.user).join('')

      this.props.authStore.toggleFormBlocker(shouldBlock)
    }

    handleSuccess = ({ path, success_message }) => {
      if (success_message) Validation.succeed(success_message)
      if (path) {
        window.location = generateRedirectLink({
          currentUrl: window.location.href,
          path,
        })
      }
    }

    handleRequestErrors = data => {
      const errorsJson = _.get(data, 'responseJSON.errors', null)
      const errors = _.isPlainObject(errorsJson)
        ? errorsJson
        : { base: [errorsJson || UNDEFINED_ERROR] }

      this.props.authStore.updateErrors(errors)
      if (!errorsJson && window.Bugsnag) {
        Bugsnag.notify(
          { name: 'Empty errors for auth', message: 'Catching error' },
          event => {
            event.addMetadata('custom', {
              data,
            })
          },
        )
      }
    }

    render() {
      const { mode, authStore } = this.props

      return (
        <div>
          <h1 className="App-title is-hero mbxl">{this.config.hero}</h1>

          <form
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            className="base-form"
          >
            <FormData config={this.config} store={authStore} mode={mode} />
          </form>
        </div>
      )
    }
  },
)

export const AuthContainer = _.flow(inject('authStore'))(AuthDumb)

/* -----------------------------
  Auth with stores
----------------------------- */
export default function Auth(props) {
  return (
    <Provider authStore={new AuthStore()}>
      <AuthContainer {...props} />
    </Provider>
  )
}
